<template>
  <v-navigation-drawer :value="nav" :mini-variant="localMini" app color="new_color">
    <template v-slot:prepend>
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title style="margin-left: 25px">{{ $store.state.user.name }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
    <v-divider></v-divider>

    <v-list dense>
      <router-link v-for="item in items" :class="{ 'disabled': item.disabled }" :key="item.title" :to="item.route">
        <v-list-item v-ripple :key="item.title" :class="{ 'item-disabled': item.disabled }">
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-list-item-icon v-bind="attrs" v-on="on">
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
            </template>
            <span>{{ item.title }}</span>
          </v-tooltip>

          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>

          <v-list-item-action v-if="item.disabled" class="block">
            <v-icon>mdi-lock</v-icon>
          </v-list-item-action>
        </v-list-item>
      </router-link>
    </v-list>

    <template v-slot:append>
      <v-list-item>
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-list-item-icon v-bind="attrs" v-on="on" style="margin:0px;">
              <div v-show="$store.state.status_connection === 'connected'" class="status">
                <v-badge bordered left color="#29cc90" dot offset-x="-5" offset-y="0"></v-badge>
              </div>
              <div v-show="$store.state.status_connection === 'disconnected'" class="status">
                <v-badge bordered left color="#992630" dot offset-x="-5" offset-y="0"></v-badge>
              </div>
              <div v-show="$store.state.status_connection === 'connecting'" class="status">
                <v-badge bordered left color="blue" dot offset-x="-5" offset-y="0"></v-badge>
              </div>
            </v-list-item-icon>
          </template>
          <span> Status conexão: {{ statusText }} </span>
        </v-tooltip>
      </v-list-item>
      <v-list-item style="padding:8px;">
        <v-list-item-content style="font-size: 9px;">
          v {{ $store.state.version }}
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" @click="doLogout">
              mdi-logout-variant
            </v-icon>
          </template>
          <span>Sair</span>
        </v-tooltip>
      </v-list-item>
    </template>
  </v-navigation-drawer>
</template>

<script>
export default {
  props: ["mostrar_barra_nav_lateral"],
  name: "BaseNavigation",
  data() {
    return {
      localMini: true,
      nav: this.mostrar_barra_nav_lateral,
      items: [
        { title: "Plataforma", icon: "mdi-radar", route: "/", disabled: false },
        { title: "Leituras", icon: "mdi-tune", route: "/racionais/confighomologacao", disabled: true },
        { title: "Monitoramento", icon: "mdi-radio-tower", route: "/racionais/monitoramentohomologacao", disabled: true },
        { title: "Algoritmos", icon: "mdi-robot-confused-outline", route: "/racionais/algoritmohomologacao", disabled: true },
        { title: "Tickers", icon: "mdi-reorder-horizontal", route: "/assinante/tickers", disabled: false },
        { title: "Minha conta", icon: "mdi-account", route: "/assinante/minha-conta", disabled: false },
      ],
    };
  },
  methods: {
    async doLogout() {
      await this.$mainApi.post("/api/assinante/logout");
      this.$router.push("/login");
    },
    updateMini(newValue) {
      this.nav = newValue;
      this.$emit('update:nav', newValue);
    }
  },

  watch: {
    mostrar_barra_nav_lateral(newVal) {
      this.nav = newVal; // Atualize 'nav' sempre que 'mostrar_barra_nav_lateral' mudar
    }
  },

  computed: {
    statusText() {
      const status = this.$store.state.status_connection;
      switch (status) {
        case 'connected':
          return 'Conectado';
        case 'disconnected':
          return 'Desconectado';
        case 'connecting':
          return 'Conectando';
        default:
          return 'Status desconhecido';
      }
    }
  },

  async mounted() {
    if (!this.$store.getters.getUser) {
      var assinante = await this.$mainApi.get("/api/assinante");
      if (!assinante) {
        return this.$router.push({ path: "/login" });
      } else {
        this.$store.dispatch("setUser", assinante);
      }
    }

    // Algoritmo ainda é bloqueado em prod
    this.$store.getters.getUser.racionais.forEach(element => {
      if (["CONGELAMENTO", "ARBITRAGEM", "DISTORCAO", "LEILAO", "LOTE_ESCONDIDO", "LOTAO", "SNIPER"].includes(element)) {
        this.items[1].disabled = false;
      }
      if (["MONITORAMENTO_PRESENCA_PLAYER_BOOK", "MONITORAMENTO_PRESENCA_PLAYER_TT", "MONITORAMENTO_PRESENCA_PLAYER_SALDO", "MONITORAMENTO_ANALISE_SALDO"].includes(element)) {
        this.items[2].disabled = false;
      }
      if (["ALGORITMO_POV", "ALGORITMO_TWAP"].includes(element)) {
        this.items[3].disabled = false;
      }
    });
  },
};
</script>

<style scoped>
.disabled {
  background-color: transparent !important;
  pointer-events: none;
  cursor: default;
}

.item-disabled {
  background-color: #808080;
  /* Use o código hexadecimal da tonalidade de cinza desejada */
}

.block {
  margin-right: -40px;
  margin-left: 0px !important;
  height: 10px;
}

.status {
  margin-left: 20px;
}
</style>